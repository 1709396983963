import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import React, { useEffect, useRef, useState } from "react";
import Geolocation from "react-native-geolocation-service";
import { useHistory } from "react-router-dom";
import { ErrorNotification } from "../../components/ErrorNotification";
import { IBaseMenuItem } from "../../components/NavBar/IBaseMenuItem";
import { NavBar } from "../../components/NavBar/NavBar";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { SpinnerBox } from "../../components/Spinner/SpinnerBox";
import { permissions } from "../../constants/permissions";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useStrings } from "../../hooks/useStrings";
import { IPOCPT } from "../../model/terminal/IPOCPT";
import { fetchSecuredPassesDatasource, insertPass } from "../../redux/actions/terminal";
import { selectAuthIxsRef, selectIxsRef } from "../../redux/selectors/selectIxsRef";
import styles from "../Terminal/Terminal.module.scss";

const defaultConfig = [1, 21, -1, -1, -1, -1];
const delay = 5000;

export default function Terminal() {
    const mounted = useRef(false);
    const strings = useStrings();

    const defaultItem: IPOCPT = {
        PRUCHOD_TERM: strings.terminals.ChoosePass,
        PRITOMNOST: -1,
        PRUCHOD_SMER: 0,
    };

    const [position, setPosition] = useState("");
    const [note, setNote] = useState("");
    const [dialogSettings, setDialogSettings] = useState(false);
    const [config, setConfig] = useState(defaultConfig);
    const [tempConfig, setTempConfig] = useState(defaultConfig);

    const [locatingDone, setLocatingDone] = useState(false);
    const [passToInsert, setPassToInsert] = useState<number>();

    const ixsRef = useAppSelector(selectIxsRef);
    const authIxsRef = useAppSelector(selectAuthIxsRef);
    const data = useAppSelector(s => s.session.terminals.passes);
    const isFetching = useAppSelector(s => s.session.terminals.isFetching || s.session.insertPass.isFetching);
    const isGPSPositionAllowed = useAppSelector(
        s => !s.session.user.token?.userDisabledActions.some(p => p === permissions.terminal.gps) ?? false
    );
    const passesFetchError = useAppSelector(s => s.session.terminals.error);
    const history = useHistory();

    const dispatch = useAppDispatch();

    const handleMenuItemClick = (item: IBaseMenuItem) => {
        switch (item.id) {
            case 1:
                openSettingsDialog();
                break;
            default:
                item.id && console.warn("Unhandled menu item: ", item);
        }
    };

    const openSettingsDialog = () => {
        setDialogSettings(true);
        setTempConfig([...config]);
    };

    useEffect(() => {
        transferOldConfig();
        dispatch(fetchSecuredPassesDatasource());
    }, [dispatch]);

    useEffect(() => {
        mounted.current = true;
        if (isGPSPositionAllowed && window.isSecureContext) {
            Geolocation.getCurrentPosition(
                pos => {
                    if (mounted.current) {
                        setPosition(`${pos.coords.latitude},${pos.coords.longitude}`);
                        setLocatingDone(true);
                    }
                },
                error => {
                    console.log(error.code, error.message);
                    if (mounted.current) {
                        setLocatingDone(true);
                    }
                },
                { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
            );
        } else {
            setLocatingDone(true);
        }
        return () => {
            mounted.current = false;
        };
    }, [isGPSPositionAllowed]);

    const transferOldConfig = () => {
        const item = localStorage.getItem("terminalConfiguration");
        if (item) {
            const oldConfig = JSON.parse(item);
            const newConfig = [
                oldConfig.button1?.PRITOMNOST ?? -1,
                oldConfig.button2?.PRITOMNOST ?? -1,
                oldConfig.button3?.PRITOMNOST ?? -1,
                oldConfig.button4?.PRITOMNOST ?? -1,
                oldConfig.button5?.PRITOMNOST ?? -1,
                oldConfig.button6?.PRITOMNOST ?? -1,
            ];
            localStorage.setItem("terminalConf", JSON.stringify(newConfig));
            localStorage.removeItem("terminalConfiguration");
        }
    };

    useEffect(() => {
        const filterDisabled = (cnf: number[]) => cnf.map(m => (data?.some(ipo => ipo.PRITOMNOST === m) ? m : -1));
        const item = localStorage.getItem("terminalConf");
        const newConfig = item ? filterDisabled(JSON.parse(item)) : defaultConfig;
        setConfig(newConfig);
    }, [data]);

    const onDialogClose = (operation: number) => {
        if (operation === 1) {
            localStorage.setItem("terminalConf", JSON.stringify(tempConfig));
            setDialogSettings(false);
            setConfig(tempConfig);
        } else {
            setDialogSettings(false);
        }
    };

    const handleChange = (e: DropDownListChangeEvent, i: number) => {
        const cnf = [...tempConfig];
        cnf[i] = e.target.value.PRITOMNOST ?? -1;
        setTempConfig(cnf);
    };

    const getClassName = (pritomnost: number) => {
        const pruchod_smer = data?.find(f => f.PRITOMNOST === pritomnost)?.PRUCHOD_SMER;
        return pruchod_smer ? (pruchod_smer === 1 ? styles.greenColor : styles.redColor) : "";
    };

    const createPassDelayed = (pritomnost: number) => {
        setPassToInsert(pritomnost);
        if (!locatingDone) {
            setTimeout(() => {
                if (mounted.current) {
                    setLocatingDone(true);
                }
            }, delay);
        }
    };

    useEffect(() => {
        if (locatingDone) {
            if (passToInsert !== undefined && passToInsert > -1) {
                dispatch(
                    insertPass({
                        IXS_REF: ixsRef,
                        IXS_REF_ZMENA: authIxsRef,
                        PRITOMNOST: passToInsert,
                        CAS: new Date(),
                        TERMINAL: -3,
                        PLATNOST: 1,
                        POZNAMKA: note,
                        GPS: position,
                    })
                );
                setNote("");
            }
            setPassToInsert(undefined);
        }
    }, [dispatch, authIxsRef, ixsRef, locatingDone, note, passToInsert, position]);

    const SettingsDropdown = (props: { position: number; fixed?: boolean }) => {
        const pritomnost = tempConfig[props.position];
        const ipocpt = pritomnost > -1 ? data?.find(f => f.PRITOMNOST === pritomnost) : undefined;
        return props.fixed ? (
            <div className={styles.settingsDropdownlist}>
                <Button className={getButtonStyle(ipocpt?.PRUCHOD_SMER)}>
                    {data?.find(f => f.PRITOMNOST === tempConfig[props.position])?.PRUCHOD_TERM ?? ""}
                </Button>
            </div>
        ) : (
            <div className={styles.settingsDropdownlist + " " + getButtonStyle(ipocpt?.PRUCHOD_SMER)}>
                <DropDownList
                    data={data}
                    textField="PRUCHOD_TERM"
                    dataItemKey="PRITOMNOST"
                    value={ipocpt}
                    onChange={e => handleChange(e, props.position)}
                />
            </div>
        );
    };

    const getButtonStyle = (direction: number | null | undefined): string => {
        return direction === 1 ? styles.greenColor : direction === 2 ? styles.redColor : styles.grayColor;
    };

    const PassButton = (props: { position: number }) => {
        const pritomnost = config[props.position];
        const ipocpt = pritomnost > -1 ? data?.find(f => f.PRITOMNOST === pritomnost) : undefined;

        return (
            <Button
                className={getClassName(config[props.position])}
                onClick={() => pritomnost > -1 && createPassDelayed(config[props.position])}
            >
                {ipocpt?.PRUCHOD_TERM ?? ""}
            </Button>
        );
    };

    return (
        <PageLayout
            header={
                <NavBar
                    canNavigateRoot
                    label={strings.dashboard.OnlineTerminal}
                    menu={{
                        items: [{ id: 1, content: strings.terminals.Settings }],
                        onItemClick: handleMenuItemClick,
                    }}
                >
                    <Button primary onClick={() => history.push("/lastPasses")}>
                        {strings.lastPasses.TitleLP}
                    </Button>
                </NavBar>
            }
        >
            {isFetching || passToInsert !== undefined ? (
                <SpinnerBox />
            ) : (
                <>
                    {passesFetchError ? (
                        <ErrorNotification error={passesFetchError} />
                    ) : (
                        <div className={styles.layout}>
                            <TimePanel />
                            <div className={styles.row}>
                                <PassButton position={0} />
                                <PassButton position={1} />
                            </div>
                            <div className={styles.row}>
                                <PassButton position={2} />
                                <PassButton position={3} />
                            </div>
                            <div className={styles.row}>
                                <PassButton position={4} />
                                <PassButton position={5} />
                            </div>
                            <div>
                                <Input
                                    label={strings.unavailability.NoteComment}
                                    value={note}
                                    onChange={e => setNote(e.value)}
                                />
                            </div>
                            <div className={styles.dropdownlist}>
                                <DropDownList
                                    data={data?.slice(1)}
                                    textField="PRUCHOD_TERM"
                                    dataItemKey="PRITOMNOST"
                                    value={defaultItem}
                                    onChange={e => createPassDelayed(e.value.PRITOMNOST)}
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
            {dialogSettings && (
                <Dialog
                    title={
                        <div className={styles.dialogTitle}>
                            <label>{strings.terminals.Settings}</label>
                        </div>
                    }
                    width="100%"
                    closeIcon={false}
                    className={styles.dialog}
                    contentStyle={{ padding: "1px" }}
                >
                    <div className={styles.settingsLayout}>
                        <div className={styles.row}>
                            <SettingsDropdown position={0} fixed />
                            <SettingsDropdown position={1} fixed />
                        </div>
                        <div className={styles.row}>
                            <SettingsDropdown position={2} />
                            <SettingsDropdown position={3} />
                        </div>
                        <div className={styles.row}>
                            <SettingsDropdown position={4} />
                            <SettingsDropdown position={5} />
                        </div>
                    </div>
                    <DialogActionsBar>
                        <Button onClick={() => onDialogClose(0)}>{strings.common.Close}</Button>
                        <Button onClick={() => onDialogClose(1)} style={{ color: "green" }}>
                            {strings.common.Save}
                        </Button>
                    </DialogActionsBar>
                </Dialog>
            )}
        </PageLayout>
    );
}

function TimePanel() {
    const timeUpdateTimer = useRef<NodeJS.Timer>();
    const [time, setTime] = useState(new Date().toLocaleString());
    useEffect(() => {
        timeUpdateTimer.current = setInterval(() => setTime(new Date().toLocaleString()), 1000);
        return () => timeUpdateTimer.current && clearInterval(timeUpdateTimer.current);
    }, []);
    return <label className={styles.timer}>{time}</label>;
}
