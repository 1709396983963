export const en = {
    common: {
        AllCatsLabel: "<All>",
        Category: "Category",
        Begin: "Begin",
        Cancel: "Cancel",
        Center: "Center",
        Close: "Close",
        Confirm: "Confirm",
        Day: "Day",
        Delete: "Delete",
        DeleteAll: "Delete all",
        End: "End",
        ErrorNotification: "An unexpected error occurred.",
        ErrorNotificationClient: "Data not available, check your connection.",
        ErrorNotificationServer: "An error occurred on the server, contact your administrator.",
        For: "For",
        Person: "Person",
        Change: "Change",
        Help: "Help",
        Label: "Label",
        Language: "Language",
        NameNote: "Name",
        No: "No",
        Approve: "Approve",
        Deny: "Reject",
        NoItems: "No items",
        OC: "Personal number",
        Save: "Save",
        Shortcut: "Shortcut",
        Yes: "Yes",
        continue: "Continue",
    },
    app: {
        ConfigurationDownloading: "Configuration downloading",
        LicenseChecking: "License checking",
        ServerChecking: "Server checking",
        Msg_AppDisabledByLicense: "Application usage disabled by license.",
        PageNotFound: "Page not found.",
        PageNotFoundTitle: "404",
    },
    dashboard: {
        Balance: "Balance",
        BalanceError: "Balance error",
        VacationBalance: "Vacation balance",
        EmailForNotifications: "Email for notifications",
        Logout: "Logout",
        DepartmentPlan: "Department plan",
        FreeShifts: "Free Shifts",
        MyAccount: "My account",
        MyPlan: "My plan",
        MyRequests: "My requests",
        MyUnavailabilities: "My unavailability",
        OnlineTerminal: "Terminal",
        MyWorksheet: "My worksheet",
        Reports: "Reports",
        RequestsToApprove: "Requests to approve",
        RequestsToApprovePlnunava: "Unavailabilities to approve",
        PersonalCard: "Personal card",
        PlansStates: "States of plans",
        Presence: "Presence",
        WorkSheetStates: "States of worksheets",
        NotComputed: "Not computed",
        Days: "Days",
        Hours: "Hours",
        Hours4: "Hours",
        AdditionalVacation: "Additional vacation balance I",
        AdditionalVacation2: "Additional vacation balance II",
        Overtime: "My overtime",
    },
    lastPasses: {
        TitleLP: "Passes",
        NoPasses: "No passes...",
    },
    login: {
        Username: "Username",
        Password: "Password",
        Remember: "Remember me",
        Login: "Login",
        LoginDialog: "Login dialog",
        CustomerCode: "Customer code",
        CustomerCodeChange: "Change customer code",
        login_error_user_not_registered: "Login error: User does not have a WebTime account.",
        login_error_invalid_grant: "Invalid username or password.",
        login_error_invalid_grant_multitenant: "Invalid username, password or customer code.",
        login_error_locked_account: "Account temporary locked for {0} minutes.",
        login_error_app_usage_not_allowed: "Invalid username or password.",
        login_error_user_expired: "The person is not valid.",
        //login_error_app_usage_not_allowed: 'You are not allowed to use this app.',
    },
    passwordChange: {
        title: "Password Change",
        oldPassword: "Original password",
        newPassword: "New password",
        newPasswordVerification: "New password verification",
        error_not_same: "New passwords do not match!",
        error_contains_username: "Password can not contain user name",
        error_contains_3_chars: "Password can not contain 3 same characters in a row",
        error_contains_sequence: "Password can not contain a rising sequence of characters",
        error_password_in_history: "The new password is identical with one password in history!",
        error_invalid_old_password: "The original password is not valid!",
    },
    reports: {
        Params: "Enter parameters",
        LoadReport: "Load",
    },
    requests: {
        Approve: "Appprove",
        Approved: "Appproved",
        ApprovedBy: "Appproved by",
        CancelRequest: "Cancel request",
        Canceled: "Canceled",
        Comment: "Note",
        CreatedReq: "Created",
        DaysUpTo4: "days",
        DefaultAccount: "Choose account",
        ErrorRequiredNote: "In case of rejecting you must enter a note.",
        ErrorRequiredNoteUnava: "A note is required to enter a request.",
        Expired: "After deadline",
        ForPerson: "For Person",
        FromDate: "From",
        Fullday: "Full day",
        IsWrongDayOrder: "The end day cannot be before the start day!",
        NewReq: "New",
        NewRequest: "New request",
        NewRequestFor: "New request for...",
        NoData: "No requests...",
        NoAccounts: "No accounts for workflow",
        Pending: "Pending",
        Reject: "Reject",
        Rejected: "Rejected",
        RejectedElectronically: "Rejected electonically",
        RejectedHand: "Rejected by hand",
        RejectedBy: "Rejected by",
        RemainingDay: "1 day left for approval",
        RemainingDays: "$0$ days left for approval",
        RemainingDaysUpTo4: "$0$ days left for approval",
        RequestSingle: "Request",
        ReqForApproval: "Requests",
        Search: "Search...",
        ShowOnly: "Only pending requests",
        StateName: "State",
        ToDate: "To",
        Type: "Type",
        SelectAll: "Select all",
        ApproveSelected: "Approve selected requests?",
        RejectSelected: "Reject selected requests?",
        MassApprove: "Mass approval",
    },
    terminals: {
        GPSText: "with location",
        ChoosePass: "Select a passage",
        NotificationText: "",
        NotificationEnText: "passage was inserted",
        NotificationErrorText: "Pass was not inserted due to connection error!",
        Settings: "Settings",
        TitleTerminals: "Terminal",
        NoPass: "<No pass>",
    },
    personalCard: {
        EmploymentNumber: "Employment number",
        Phone: "Phone",
        Profession: "Profession",
        ValidFrom: "Valid from",
        ValidTo: "Valid to",
        LastPassage: "Last passage",
    },
    unavailability: {
        CreatedUnavailability: "Created",
        New: "New",
        NewUnavailability: "New unavailability",
        NoteComment: "Note",
        TimeFrom: "Time from",
        TimeTo: "Time to",
        Unavailability: "My unavailability",
        Unavailabilities: "Unavailabilities",
    },
    worksheet: {
        Absence: "Abs.",
        AccountDefName: "Account",
        ApprovedWS: "Approved by",
        BalanceNext: "Balance in the next period",
        CalendarFund: "Calendar fund",
        ComputeTo: "Compute to",
        Confirmed: "Confirmed by",
        DaysWS: "Days",
        ErrorWS: "Error",
        Model: "Model",
        MonthAccounts: "Monthly accounts",
        MyWorksheetTitle: "Worksheet",
        NameWS: "Name",
        NoDataWorksheetNull: "No data, please check if you have rights to see your worksheet.",
        PassesWS: "Passes",
        Period: "Period",
        ShiftsFund: "Shift fund",
        Sign: "Sign",
        Signature: "Signed",
        SignTitle: "Signing worksheet",
        SignatureNeededWarning: "Sign your worksheet!",
        State_Approved: "Approved",
        State_Confirmed: "Confirmed",
        State_Open: "Opened",
        State_SignedElectronically: "Signed electronically",
        State_SignedHandwritten: "Signed by hand",
        TransferPast: "Transfer from the past",
        TransferNext: "Transfer to the next period",
        Worked: "Work.",
        Attachments: "Attachments",
        NoAttachments: "No attachments are added.",
        TakePicture: "Take a picture",
        AttachmentName: "Name",
        AttachmentNote: "Note",
        NewAttachemnt: "New attachment",
        Upload: "Upload",
    },
    worksheetStates: {
        TitleWSStates: "Worksheet states",
        AllChosen: "All",
        OnlyApprovedWS: "Only approved",
        OnlyCreated: "Only opened",
        OnlyConfirmed: "Only confirmed",
        OnlySigned: "Only signed electronically",
        OnlySignedHandwritten: "Only by hand",
        OnlyUnSigned: "Only unsigned",
    },
    planStates: {
        Application: "Application",
        Checked: "Checked electronically",
        CheckedHand: "Checked by hand",
        Unchecked: "Unchecked",
        Checkedby: "Checked by",
        ChangeFrom: "Changed from",
        ChangedBy: "Approved by",
        DateFrom: "Period start",
        DateTo: "Period end",
        DetailCheck: "Check detail",
        DetailRequest: "Detail of change request",
        NotPassed: "Not passed",
        OnlyNotPassed: "Only not passed",
        OnlyChecked: "Only Checked electronically",
        OnlyCheckedHand: "Only Checked by hand",
        OnlyUnchecked: "Only Unchecked",
        OnlyApprovedPS: "Only Approved electronically",
        OnlyApprovedPSHand: "Only Approved by hand",
        OnlyRejected: "Only Rejected electronically",
        OnlyRejectedHand: "Only Rejected by hand",
        Waiting: "Waiting",
        OnlyWaiting: "Only Waiting",
        PreferedKind: "Preferred kind",
        ApprovedState: "Approved electronically",
        ApprovedStateHand: "Approved by hand",
        TitlePS: "Plan states",
    },
    plan: {
        WasSubmittedBy: "Was submitted by",
        PlanWasChangedBy: "Plan was changed by",
        Legend: "Legend",
        DetailPlan: "Detail",
        RequestDetail: "Request detail",
        PlanChange: "Plan change",
        ApprovalOfPlanChanges: "Approval of plan changes",
        AcquaintanceWithPlan: "Acquaintance with a plan",
        Msg_ConfirmAcquaintWithYourPlanPlease: "Confirm acquaint with your plan please.",
        Msg_ApprovePlanChanges: "Approve the plan changes.",
        PlanName: "Plan",
        N3Days: "3-Days",
        Week: "Week",
        Msg_TooMuchPersons: 'Center "{0}" includes more persons than is allowed to load ({1}).',
        OutsideOfWorkplace: "Outside of workplace",
        Recalculate: "Recalculate plans",
        NotPublished: "The plan is not published for this month.",
        ContainsUnpublished: "This month contains an interval with an unpublished plan.",
    },
    presence: {
        DetailPresence: "Presence detail",
        AllPersons: "All persons",
        Present: "Present",
        OnlyInconsistent: "Only with presence inconsistencies",
        OnlyNotPresent: "Only absent persons",
        OnlyPresent: "Only present persons",
        OnlyWithShift: "Only with planned shifts",
        LastPassName: "Last pass",
        LastPassTime: "Time of last pass",
        MaxCount: 'Center "{0}" includes more persons than is allowed to load (1000).',
        ShiftName: "Shift",
        Absent: "Absent",
        Unknown: "Unknown",
        TerminalPres: "Terminal",
        TitlePresence: "Presence",
    },
    overtime: {
        Title: "Overtime",
        DialogTitle: "Overtime",
        Day: "Day",
        Note: "Note",
        From: "Start",
        To: "End",
        Account: "Account",
    },
    debug: {
        ErrorsMany: "Errors",
        ShowError: "Show errors",
        Msg_ClickForCopyDetailsIntoClipboard: "Click for copy details into clipboard.",
        Msg_DetailsSuccessfullyCopiedIntoClipboard: "Details successfully copied into clipboard.",
    },
    centerPicker: {
        NotSelected: "Not selected",
        NoSubcenters: "No subcenters",
        IncludingSubcenters: "Including subcenters",
    },
    responses: {
        over_limit: "Vacation balance exceeded.",
        period_locked: "The worksheet for the period is already confirmed.",
        attachment_upload_success: "Attachment upload succeeded.",
        attachment_upload_error: "Attachment upload failed.",
        date_reserved_unava: "Selected period already contains unavailability.",
        license_error: "Number of SelfService users exceeds the number of licenses.",
        free_shift_assigned: "Shift assigned",
        free_shift_unavailable: "Shift no longer available",
        err_labor_law: "The shift cannot be assigned due to a labor code violation.",
    },
    actions: {
        DepartmentPlanSelfServiceApp_RunApp: "Department plan",
        MyPlanSelfServiceApp_RunApp: "My plan",
        MyRequestsSelfServiceApp_RunApp: "My requests",
        MyUnavailabilitiesSelfServiceApp_RunApp: "My unavailability",
        MyWSSelfServiceApp_RunApp: "My worksheet",
        OvertimeSelfServiceApp_RunApp: "My overtime",
        PlanStatesSelfServiceApp_RunApp: "States of plans",
        PresenceSelfServiceApp_RunApp: "Presence",
        ReportsSelfServiceApp_RunApp: "Reports",
        RequestsApprovalSelfServiceApp_RunApp: "Requests to approve",
        TerminalSelfServiceApp_RunApp: "Terminal",
        UnavasApprovalSelfServiceApp_RunApp: "Unavailabilities to approve",
        WorksheetStatesSelfServiceApp_RunApp: "States of worksheets",
    },
    substitutions: {
        Person: "Person:",
        From: "From:",
        To: "To:",
        Substitution: "Substitution",
        Add: "Add",
        Edit: "Edit",
        Undo: "Undo",
    },
    freeShifts: {
        Days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", "Holiday"],
        CentersConfig: "Preferred centers",
        TimesConfig: "Preferred times",
        NotifConfig: "Notifications",
        Selection: "Shift selection",
        Date: "Date",
        Center: "Center",
        Start: "Start",
        End: "End",
        Category: "Category",
        Categories: "Categories",
        ErrorMin: "The shift can be extended by a maximum of $0$ minutes. (Currently extended by $1$ minutes.)",
        ErrorMax: "The shift can be shortened by a maximum of $0$ minutes. (Currently shortened by $1$ minutes.)",
        CenterSelection: "Center selection",
        All: "All",
        Preferred: "Preferred",
        Others: "Others",
        CancelShift: "Cancel shift",
    },
};
