import { createReducer, SerializedError } from "@reduxjs/toolkit";
import {
    fetchAwData,
    fetchAwShifts,
    resetAwData,
    scrollAwNext,
    scrollAwPrevious,
    setAwCheckedCenters,
    setAwNotifications,
    setAwPreferred,
    setAwTimes,
} from "../actions/agreementWorkers";
import { IAccount } from "../../model/plan/types";

const AW_CENTER_CHECKED = "aw_center_checked";

export interface AwCenter {
    ixsStr: string;
    name: string;
    preferred: boolean;
}

export interface AwTime {
    enabled: boolean;
    day: number;
    start: Date | string;
    end: Date | string;
}

export interface AwNotification {
    enabled: boolean;
    day: number;
    time: Date | string;
}

export interface AwTimeInfo {
    default: string;
    min: string;
    max: string;
}

export interface AwSelectedShift {
    id: number;
    center: string;
    day: Date;
    start: Date;
    end: Date;
    category: number;
}

export interface AwShift {
    id: number;
    day: string;
    center: {
        id: string;
        name: string;
    };
    start: AwTimeInfo;
    end: AwTimeInfo;
    diff: {
        min: number;
        max: number;
    };
    break: {
        start: string;
        end: string;
    };
    category: number;
}

export interface IAwDataPage {
    shifts: AwShift[];
    categories: IAccount[];
}

export interface IAwData {
    data: (IAwDataPage | undefined)[];
    shouldFetch: boolean[];
    reqId: (string | undefined)[];
    centers: AwCenter[];
    times: AwTime[];
    notifications: AwNotification[];
    checkedCenters: string[];
    step: number;
}

export interface IAwState extends IAwData {
    isFetching: boolean;
    error?: SerializedError;
    setIsFetching: boolean;
}

function fillNotifications(notifications: AwNotification[]) {
    for (let i = 1; i < 9; i++) {
        if (!notifications.find(f => f.day === i)) {
            notifications.push({
                enabled: false,
                day: i,
                time: "1900-01-01T00:00:00",
            });
        }
    }
    return notifications.sort((a, b) => a.day - b.day);
}

function fillTimes(times: AwTime[]) {
    for (let i = 1; i < 9; i++) {
        if (!times.find(f => f.day === i)) {
            times.push({
                enabled: true,
                day: i,
                start: "1900-01-01T00:00:00",
                end: "1900-01-01T23:59:59",
            });
        }
    }
    return times.sort((a, b) => a.day - b.day);
}

export const AwReducer = createReducer<IAwState>(
    {
        isFetching: false,
        setIsFetching: false,
        shouldFetch: [true, true, true],
        data: [undefined, undefined, undefined],
        reqId: [undefined, undefined, undefined],
        centers: [],
        times: [],
        notifications: [],
        checkedCenters: JSON.parse(localStorage.getItem(AW_CENTER_CHECKED) ?? "[]") as string[],
        step: 1,
    },
    builder => {
        builder
            .addCase(fetchAwData.pending, (state, action) => ({
                ...state,
                isFetching: true,
                centers: [],
                times: [],
                notifications: [],
                error: undefined,
            }))
            .addCase(fetchAwData.fulfilled, (state, action) => {
                const checked = state.checkedCenters.filter(f => action.payload.centers.map(m => m.ixsStr).includes(f));
                localStorage.setItem(AW_CENTER_CHECKED, JSON.stringify(checked));
                return {
                    ...state,
                    checkedCenters: checked,
                    isFetching: false,
                    centers: action.payload.centers,
                    times: fillTimes(action.payload.times),
                    notifications: fillNotifications(action.payload.notifications),
                    step: action.payload.step > 0 ? action.payload.step : 1,
                    error: undefined,
                };
            })
            .addCase(fetchAwData.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                data: [],
                error: action.error,
            }))
            .addCase(setAwPreferred.pending, (state, action) => ({
                ...state,
                setIsFetching: true,
                error: undefined,
            }))
            .addCase(setAwPreferred.fulfilled, (state, action) => ({
                ...state,
                setIsFetching: false,
                centers: action.payload.centers,
                times: fillTimes(action.payload.times),
                notifications: fillNotifications(action.payload.notifications),
                error: undefined,
            }))
            .addCase(setAwPreferred.rejected, (state, action) => ({
                ...state,
                setIsFetching: false,
                error: action.error,
            }))
            .addCase(setAwNotifications.pending, (state, action) => ({
                ...state,
                setIsFetching: true,
                error: undefined,
            }))
            .addCase(setAwNotifications.fulfilled, (state, action) => ({
                ...state,
                setIsFetching: false,
                centers: action.payload.centers,
                times: fillTimes(action.payload.times),
                notifications: fillNotifications(action.payload.notifications),
                error: undefined,
            }))
            .addCase(setAwNotifications.rejected, (state, action) => ({
                ...state,
                setIsFetching: false,
                error: action.error,
            }))
            .addCase(setAwTimes.pending, (state, action) => ({
                ...state,
                setIsFetching: true,
                error: undefined,
            }))
            .addCase(setAwTimes.fulfilled, (state, action) => ({
                ...state,
                setIsFetching: false,
                centers: action.payload.centers,
                times: fillTimes(action.payload.times),
                notifications: fillNotifications(action.payload.notifications),
                error: undefined,
            }))
            .addCase(setAwTimes.rejected, (state, action) => ({
                ...state,
                setIsFetching: false,
                error: action.error,
            }))
            .addCase(fetchAwShifts.pending, (state, action) => ({
                ...state,
                data: state.data.map((m, i) => (i === action.meta.arg.pageIndex ? undefined : m)),
                shouldFetch: state.shouldFetch.map((m, i) => (i === action.meta.arg.pageIndex ? false : m)),
                reqId: state.reqId.map((m, i) => (i === action.meta.arg.pageIndex ? action.meta.requestId : m)),
                error: undefined,
            }))
            .addCase(fetchAwShifts.fulfilled, (state, action) => {
                const pageIndex = state.reqId.findIndex(f => f === action.meta.requestId);
                return pageIndex > -1
                    ? {
                          ...state,
                          data: state.data.map((m, i) => (i === pageIndex ? action.payload : m)),
                          error: undefined,
                      }
                    : state;
            })
            .addCase(fetchAwShifts.rejected, (state, action) => ({
                ...state,
                error: action.error,
            }))
            .addCase(scrollAwNext, (state, action) => ({
                ...state,
                data: [state.data[1], state.data[2], undefined],
                shouldFetch: [state.shouldFetch[1], state.shouldFetch[2], true],
                reqId: [state.reqId[1], state.reqId[2], undefined],
            }))
            .addCase(scrollAwPrevious, (state, action) => ({
                ...state,
                data: [undefined, state.data[0], state.data[1]],
                shouldFetch: [true, state.shouldFetch[0], state.shouldFetch[1]],
                reqId: [undefined, state.reqId[0], state.reqId[1]],
            }))
            .addCase(resetAwData, (state, action) => ({
                ...state,
                data: [undefined, undefined, undefined],
                shouldFetch: [true, true, true],
                reqId: [undefined, undefined, undefined],
            }))
            .addCase(setAwCheckedCenters, (state, action) => {
                localStorage.setItem(AW_CENTER_CHECKED, JSON.stringify(action.payload));
                return {
                    ...state,
                    checkedCenters: action.payload,
                };
            });
    }
);
